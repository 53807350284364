// import React, { useState, useEffect } from "react";

// export const Typewriter = ({ text, speed }) => {
//   const [currentText, setCurrentText] = useState("");
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (currentIndex < text.length) {
//         setCurrentText((prevText) => prevText + text[currentIndex]);
//         setCurrentIndex((prevIndex) => prevIndex + 1);
//       } else {
//         clearInterval(interval);
//       }
//     }, speed);

//     return () => {
//       clearInterval(interval);
//     };
//   }, [text, speed, currentIndex]);

//   return <span>{currentText}</span>;
// };

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validateName = (name) => {
  return name.length > 1;
};
export const validateMessage = (message) => {
  return message.trim().length > 5;
};
