import React, { useEffect, useState } from "react";
import "./intro.scss";

const Intro = ({ demo }) => {
  const [btnState, setBtnState] = useState(true);

  useEffect(() => {
    if (btnState) {
      setTimeout(() => {
        setBtnState(false);
        setTimeout(() => {
          demo(false);
        }, 800);
      }, 5000);
    }
  }, []);
  return (
    <div className="intro-container" id={!btnState ? "sent" : ""}>
      <div className="question">
        <div>
          Who is <strong>Solarin Johnson?</strong>
        </div>
        <span
          class="material-symbols-outlined"
          title="Send"
          onClick={() => setBtnState(false)}
        >
          send
        </span>
      </div>
      {/* {!btnState && <Demo data={steps} />} */}
    </div>
  );
};

// export function Demo({ data }) {
//   const [item, setItem] = useState("");
//   useEffect(() => {}, []);
//   return (
//     <div className="demo-container">
//       <div className="demo-progress">
//         <span></span>
//       </div>
//     </div>
//   );
// }

export default Intro;
