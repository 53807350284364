import { useEffect, useState } from "react";
import "./contact.scss";
import { useInView } from "react-intersection-observer";
import { validateEmail, validateMessage, validateName } from "../../utils";
import axios from "axios";

export default function Contact() {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: false,
  });

  const handleSubmit = () => {
    const name = document.querySelector('input[type="text"]');
    const email = document.querySelector('input[type="mail"]');
    const message = document.querySelector("textarea");

    if (!validateName(name.value)) {
      setErr("Invalid Name");
      return;
    }
    if (!validateEmail(email.value)) {
      setErr("Invalid Email Address");
      return;
    }
    if (!validateMessage(message.value)) {
      setErr("Message too short");
      return;
    }
    setErr(false);

    setLoading(true);
    axios
      .post("https://maildistrib-1-j3929121.deta.app/dotjs", {
        name: name.value,
        email: email.value,
        message: message.value,
      })
      .then((response) => {
        // Handle successful response
        console.log(response.data);
        setErr("Message Sent Successfully");
        name.value = "";
        email.value = "";
        message.value = "";
        setLoading(false);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
        setErr("Failed to send message");
        setLoading(false);
      });
  };
  useEffect(() => {
    if (err) {
      setTimeout(() => {
        setErr(false);
      }, 5000);
    }
  }, [err]);
  return (
    <div
      className={`about-container contact-container ${inView ? "inView" : ""}`}
      id="contact"
      ref={ref}
    >
      <div className="about-head contact-head">
        Contact <span>Me</span>
      </div>
      <div className="contact-form">
        {err && (
          <div className="contact-err">
            {err}
            <span
              class="material-symbols-rounded"
              onClick={() => setErr(false)}
            >
              close
            </span>
          </div>
        )}
        <div id="input">
          <input type="text" placeholder="Full Name" />
          <input type="mail" placeholder="Email Address" />
        </div>
        <div id="message">
          <textarea
            name="message"
            cols="30"
            rows="10"
            placeholder="Message..."
          ></textarea>
        </div>
      </div>
      <div className="contact-button" onClick={handleSubmit}>
        {loading ? (
          <>
            <i class="fa-solid fa-circle-notch fa-spin"></i>
            <span>Submitting</span>
          </>
        ) : (
          <span>Submit</span>
        )}
      </div>
    </div>
  );
}
