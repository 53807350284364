import { useEffect, useState } from "react";
import "./experience.scss";
import { useInView } from "react-intersection-observer";

export default function Experience({ browserWidth }) {
  const [ref, inView] = useInView({
    threshold: 0.25,
    triggerOnce: false,
  });

  const experience = [
    {
      head: "Garri-ui",
      body: "Garri-ui is a playground webpage which contains complex parallax animations. It is built using React.js.",
      link: "https://garri-ui.vercel.app/",
    },
    {
      head: "Car Animation 3d ",
      body: "A 3D car animation built using React Spring. It showcases different car models and their animations.",
      link: "https://car-animation-3d.vercel.app/",
    },
    {
      head: "Freestyles",
      body: "Collection of freestyle animations and interactions featured in my Codepen projects.",
      link: "https://codepen.io/dotjs",
    },
  ];
  const projects = [
    {
      date: ["Oct 2023", ""],
      head: "Anonnchat",
      body: "Anonnchat is a platform for anonymous chatting with strangers. It provides inbox and realtime conversations without revealing identities.",
      link: "https://www.anonnchat.fun/",
    },
    {
      date: ["May 2024", ""],
      head: "Splitwise Clone (Landing Page)",
      body: "A clone of the popular expense sharing app, Splitwise. It allows users to split bills and track expenses with friends.",
      link: "https://splitwise-omega.vercel.app/",
    },
    {
      date: ["Dec 2023", ""],
      head: "Realestate Landing Page",
      body: "A landing page for a real estate company. It showcases properties and provides a contact form for inquiries.",
      link: "https://www.olamashproperties.com/",
    },
  ];
  return (
    <div
      className={`experience-container ${
        inView ? "inView" : browserWidth <= 600 ? "inView" : ""
      }`}
      ref={ref}
    >
      <div className="experience-head">
        My <span>Journey</span>
      </div>
      <div className="experience-window-container">
        <ExperienceWindow head={"Top Projects"} data={projects} />
        <ExperienceWindow head={"Other Projects"} data={experience} />
      </div>
    </div>
  );
}

export const ExperienceWindow = ({ head, data }) => {
  return (
    <div className="experience-window">
      <div className="experience-window-head">{head}</div>
      <div className="experience-window-content">
        <div className="experience-window-bar">
          {data.map(() => (
            <span></span>
          ))}
        </div>
        <div className="experience-window-body">
          {data.map((item, i) => (
            <ExperienceTab data={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export const ExperienceTab = ({ data }) => {
  return (
    <div className="experience-tab">
      {
        <div className="experience-tab-date">
          <i class="fa-solid fa-calendar-days"></i>
          {data.date
            ? `${data.date[0]} ${data.date[1] && "— " + data.date[1]}`
            : ""}
        </div>
      }
      <div className="experience-tab-title">{data.head}</div>
      <div className="experience-tab-body">{data.body}</div>
      <div className="experience-tab-link">
        Visit:{" "}
        <a href={data.link} target="_blank" rel="noreferrer">
          {data.link}
        </a>
      </div>
      <span className="tab-animation"></span>
    </div>
  );
};
