import "./home.scss";
import { useInView } from "react-intersection-observer";

export default function Home({ browserWidth }) {
  const [ref, inView] = useInView({
    threshold: 0.45,
    triggerOnce: false,
  });
  const handleRedirect = (url, target = "_blank") => {
    target ? window.open(url, target) : window.location.replace(url);
  };

  return (
    <div
      className={`home-container ${inView ? "inView" : "outView"}`}
      ref={ref}
    >
      <div className="home-content">
        <div className="home-content-name">Hi, I'm Solarin Johnson</div>
        <div className="home-content-label">Frontend Developer</div>
        <div className="home-content-text">
          I'm a skilled Frontend developer, proficient in creating engaging,
          intuitive user interfaces with modern technologies. I focus on design
          and user experience to build functional, visually appealing web
          applications. Let's collaborate to create something innovative and
          user-friendly!
        </div>
        <div className="home-content-button">
          <div onClick={() => handleRedirect("#contact", false)}></div>
          <div
            onClick={() => handleRedirect("https://wa.me/qr/RHEGCYSRDWLEO1")}
          ></div>
        </div>
        <div className="home-content-social">
          <div onClick={() => handleRedirect("https://twitter.com/S0LARIN")}>
            <span></span>
            <i className="fa-brands fa-twitter"></i>
          </div>
          <div
            onClick={() => handleRedirect("https://github.com/Solarin-johnson")}
          >
            <span></span>
            <i className="fa-brands fa-github"></i>
          </div>
          <div
            onClick={() =>
              handleRedirect("https://www.linkedin.com/in/solarin-johnson/")
            }
          >
            <span></span>
            <i className="fa-brands fa-linkedin-in"></i>
          </div>
          <div onClick={() => handleRedirect("https://codepen.io/dotjs")}>
            <span></span>
            <i className="fa-brands fa-codepen"></i>
          </div>
        </div>
      </div>
      <div className="home-image">
        <img
          fetchpriority="high"
          src={process.env.PUBLIC_URL + "/image.webp"}
          alt=""
        />
      </div>
      <div className="overlay"></div>
    </div>
  );
}
