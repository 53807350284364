import "./about.scss";
import { useInView } from "react-intersection-observer";

export default function About() {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  return (
    <div className={`about-container ${inView ? "inView" : ""}`} ref={ref}>
      <div className="about-head">
        About <span>Me</span>
      </div>
      <div className="about-image">
        <img
          fetchpriority="high"
          src={process.env.PUBLIC_URL + "/image.webp"}
          alt=""
        />
      </div>
      <div className="about-title">Solarin Johnson</div>
      <div className="about-content">
        Hi, I'm Johnson. A Frontend developer skilled in HTML, CSS, SCSS,
        JavaScript - (React.js). I specialize in creating engaging web
        experiences and transforming complex requirements into user-friendly
        solutions. I'm passionate about building scalable and responsive web
        <span>Let's build the future of technology together!</span>
      </div>
    </div>
  );
}
