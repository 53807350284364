import { useEffect, useState } from "react";
import "./footer.scss";
import { useInView } from "react-intersection-observer";

export default function Footer() {
  const [currentYear, setCurrentYear] = useState(null);
  const [ref, inView] = useInView({
    threshold: 0.45,
    triggerOnce: false,
  });

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  const scrollToTop = () =>
    document.querySelectorAll(".container")[0].scrollTo({
      top: 0,
      behavior: "smooth",
    });

  return (
    <div className={`footer-container ${inView ? "inView" : ""}`} ref={ref}>
      <p className="footer-copy">
        &copy; {currentYear} Solarin Johnson. All rights reserved.
      </p>
      <div>
        <div className="footer-btt" onClick={scrollToTop}>
          <i className="fa-solid fa-arrow-up"></i>
        </div>
      </div>
    </div>
  );
}
