import { useEffect, useState } from "react";
import "./App.scss";
import Layout from "./layout/layout";
import Navigation from "./navigation/navigation";
import Intro from "./components/intro/intro";
import { SpeedInsights } from "@vercel/speed-insights/react";

function App() {
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [isIntro, setIsIntro] = useState(true);
  useEffect(() => {
    window.addEventListener("resize", () => setBrowserWidth(window.innerWidth));
    return () =>
      window.removeEventListener("resize", () =>
        setBrowserWidth(window.innerWidth)
      );
  }, []);
  return (
    <div className="container">
      {isIntro ? (
        <Intro demo={(data) => setIsIntro(data)} />
      ) : (
        <>
          <Navigation browserWidth={browserWidth} />
          <Layout browserWidth={browserWidth} />
        </>
      )}
    </div>
  );
}

export default App;
